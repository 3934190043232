import {configs} from 'shared/config/config';
import css from './MainPage.module.css';
import Logo from 'components/logo/Logo';
import Button from 'components/button/Button';
import {ReactComponent as YandexLogo} from 'shared/icons/yandex.svg';
import {ReactComponent as DoubleGis} from 'shared/icons/2gis.svg';
import {ReactComponent as Google} from 'shared/icons/google.svg';
import React from 'react';
import ButtonContent from 'components/button-content/ButtonContent';

const MainPage = () => {
    const config = configs[0];

    return (
        <main className={css.main}>
            <Logo className={css.logo} />
            <div className={css.textContent}>
                <p>
                    Рады, что вам все понравилось!
                    Мы ценим ваше мнение и будем благодарны, если вы поделитесь им
                </p>
                <p>
                    Чтобы оставить отзыв, выберите одну из площадок ниже и нажмите на нее
                </p>
            </div>
            <div className={css.buttonsContent}>
                {config.links.yandex && (
                    <Button color='primary' onClick={() => window.open(config.links.yandex, '_blank')}>
                        <ButtonContent Icon={YandexLogo} text='Яндекс Карты' />
                    </Button>
                )}
                {config.links.doubleGis && (
                    <Button color='primary' onClick={() => window.open(config.links.doubleGis, '_blank')}>
                        <ButtonContent Icon={DoubleGis} text='2ГИС' />
                    </Button>
                )}
                {config.links.google && (
                    <Button color='primary' onClick={() => window.open(config.links.google, '_blank')}>
                        <ButtonContent Icon={Google} text='google maps' />
                    </Button>
                )}
            </div>
        </main>
    );
};

export default MainPage;
