export interface MapConfig {
  yandex?: string;
  doubleGis?: string;
  google?: string;
}

export interface FilialConfig {
  id: number;
  address: string;
  links: MapConfig;
}

export const configs: FilialConfig[] = [
  {
    id: 1,
    address: 'Arm-ecogroup',
    links: {
      yandex: 'https://yandex.ru/maps/org/arm_ekogrupp/178232576758/?ll=30.318716%2C59.855408&z=16.91',
      doubleGis: 'https://2gis.ru/spb/firm/5348552840885960?m=30.318873%2C59.855482%2F16',
    },
  },
];
