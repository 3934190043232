import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import MainPage from 'pages/main-page/MainPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
    },
]);

const AppRouter = () => (
        <RouterProvider router={router}/>
    );

export default AppRouter;
