import React from 'react';
import logo from 'shared/images/logo.png'

interface LogoProps {
    className?: string;
}

const Logo = ({className}: LogoProps) => {
    return (
        <div className={className}>
            <img src={logo} alt='Логотип' width={220}/>
        </div>
    );
};

export default Logo;
